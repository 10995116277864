import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				EcoDrive Service Center
			</title>
			<meta name={"description"} content={""} />
			<meta property={"og:title"} content={"EcoDrive Service Center"} />
			<link rel={"shortcut icon"} href={"https://arataplesta.com/imgs/6456819.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<meta name={"msapplication-TileImage"} content={"https://arataplesta.com/imgs/6456819.png"} />
			<meta name={"msapplication-TileColor"} content={"https://arataplesta.com/imgs/6456819.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Політика конфіденційності{"\n\n"}
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				1. ЗАГАЛЬНІ ПОЛОЖЕННЯ
				<br />
				<br />
				Згідно Закону України від 01 червня 2010 року № 2297-VI «Про захист персональних даних» (надалі – «Закон») під персональними даними розуміють відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована. Згідно Політики конфіденційності в поняття "особистої інформації" входить інформація, або частина інформації, котра дозволяє Вас ідентифікувати. Як правило сюди входить ім’я та прізвище, псевдонім (нік), адреса електронної пошти та номер телефону, може також входити така інформація, як IP-адреса. Такі відомості ми можемо отримати безпосередньо від Вас, наприклад, при реєстрації на сайті, наданні контактних даних, інформації отриманої із банківської картки або платежів, а також іншу особисту інформацію, яку Ви добровільно надали нам.
				<br />
				<br />
				Компанія з великою повагою ставиться до конфіденційної (персональної) інформації всіх без винятку осіб, які відвідали сайт, а також тих, хто користується наданими сайтом послугами; в зв'язку з чим, Компанія прагне захищати конфіденційність персональних даних (відомостей чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована), тим самим створивши і забезпечивши максимально комфортні умови використання сервісів Сайту кожному користувачеві.
				<br />
				<br />
				2. ВИКОРИСТАННЯ ТА МЕТА ЗБОРУ ПЕРСОНАЛЬНИХ ДАНИХ.
				<br />
				<br />
				Особиста інформація відвідувачів сайту, зібрана при реєстрації, переважно використовується для надання послуг у відповідності з Вашими потребами, в першу чергу для надання послуг або у договірних відносинах, а також для відповідей на Ваші запитання. Також ми можемо використовувати Вашу контактну інформацію в поштових відправленнях, а саме повідомляти Вас про нові можливості, акції та інші новини. Ми діємо відповідно до цієї Політики конфіденційності, на підставі Положення про обробку і захист персональних даних та на підставі чинного законодавства України.
				<br />
				<br />
				Ми маємо право зберігати Персональні дані стільки, скільки необхідно для реалізації мети, що зазначена у даній Політиці або у строки, встановлені чинним законодавством України або до моменту видалення Вами цих даних.
				<br />
				<br />
				3. НАДАННЯ ДОСТУПУ ДО ІНФОРМАЦІЇ.
				<br />
				<br />
				Надання доступу до особистої інформації іншим компаніям і приватним особам можливе лише за таких наступних обставин:
				<br />
				<br />
				Має місце наявність дозволу Користувача. Для передачі будь-якої конфіденційної інформації потрібна явна згода Користувача.
				<br />
				В випадках визначених чинним законодавством України, а також у випадках запобігання злочину або завдання шкоди нам або третім особам.
				<br />
				<br />
				4. ЗАХИСТ ІНФОРМАЦІЇ.
				<br />
				<br />
				Ми вживаємо всіх необхідних заходів для захисту даних від несанкціонованого доступу, зміни, розкриття чи знищення. До цих заходів належать, зокрема, внутрішня перевірка процесів збору, зберігання та обробки даних і заходів безпеки, включаючи відповідне шифрування і заходи щодо забезпечення фізичної безпеки даних для запобігання неавторизованого доступу до систем, в яких ми зберігаємо особисті дані.
				<br />
				<br />
				5. ЗМІНА ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ.
				<br />
				<br />
				Користувач приймає умови цієї політики конфіденційності і враховує, що дана політика конфіденційності може час від часу змінюватися. Зміни, що вносяться до політики конфіденційності, публікуються на цій сторінці. З питань щодо політики конфіденційності (в т.ч. видалення персональних даних), Користувач може зв'язатися з нами використовуючи
				<br />
				e-mail: arataplesta.com{"\n\n"}
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523c308e2dd3a0022204417"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});